import { store } from '../store';
import { streamToolConstants } from '../constants';
import {
  commonAction,
  FBAction,
  linkedinAction,
  YTAction,
  destinationActions,
  unitActions,
} from '../actions';
import { wowzaPostStatus } from '../api/wowza.api';
import { notify } from '../components/CommonNotification/CommonNotification';
import { liveStreamServices } from '../services/livestream.service';
import Cookies from 'universal-cookie';
import i18n from 'i18next';
import { unitService } from '../services';
import { round } from 'lodash';
import { getValueForEnvironment } from '../utils/setZoneValues';
import { getLocalStorageItem } from '../utils/cookieAndLocalStorageUtils';

const { switchToDestination, startUnitForStreamTool, stopStream } = unitActions;
const { fbLiveVideoStatus: fbGetLiveVideoStatus } = FBAction;
const { LIPostStatus } = linkedinAction;
const { getYTEventDetails } = YTAction;

const cookie = new Cookies();
// const items = [
//   { title: i18n.t('RECEIVINGVIDEOFEED') },
//   { title: i18n.t('PROVISIONINGSERVER') },
//   { title: i18n.t('SENDINGVIDEOFEED') },
//   { title: i18n.t('DONE') },
//   { title: i18n.t('ERROR') },
// ];
const RTMP_LINE_ID = 600000003;

export const liveStreamActions = {
  provideFeedToDestinations,
  retriveStreamToolsInfo,
  storeStreamToolsInfo,
  setWaterMark,
  removeWaterMark,
  setFallbackSlate,
  storeImageInS3,
  urlBuilder,
  getSocket,
  scope,
  receiveStreamFromLst,
  goLive,
  getProfileValue,
  setLiveStreamProgressState,
  checkDestinationsHealthToGoLive,
  updateAudio,
  isStreamtoolsStreaming,
  showStreamtoolsLoader,
  killAllExistingBroadcaster,
  stopStreamTools,
  setStreamToolsZone,
  refreshStreamToolsDestination,
  updateStreamToolsData,
  setStreamToolsProfile,
  setStreamToolsDestinations,
  setInitialDataLoadedFlag,
  setStreamToolsAdvanceProfile,
};

async function storeStreamToolsInfo(bossId) {
  const {
    streamToken: token,
    streamLiveManageId: liveManagerId,
    streamInputId: inputId,
    fallBackSlateUrl,
    fallBackSlateName,
    watermarkUrl: logoUrl,
    x,
    y,
    isStreamToolActive: toggleState,
    selectedZone,
    audioBitrate: audio_bitrate,
    fps,
    videoBitrate: video_bitrate,
  } = store.getState().streamTools?.[bossId];

  const { storeStreamToolsData } = liveStreamServices;
  await storeStreamToolsData({
    token,
    liveManagerId,
    inputId,
    bossId,
    fallBackSlateUrl,
    fallBackSlateName,
    logoUrl,
    x,
    y,
    toggleState,
    selectedZone,
    audio_bitrate,
    fps,
    video_bitrate,
  });
}

function showStreamtoolsLoader(bossId, val = true) {
  return (dispatch) => {
    const { initialDataLoaded } = store.getState().streamTools?.[bossId];
    if (initialDataLoaded) {
      val = false;
    }
    dispatch({
      type: streamToolConstants.EL_LOADING_DATA,
      payload: { bossId, isLoadingData: val },
    });
  };
}

function setInitialDataLoadedFlag(bossId, val) {
  return (dispatch) => {
    dispatch({
      type: streamToolConstants.INITIAL_DATA_LOADED,
      payload: { bossId, initialDataLoaded: val },
    });
  };
}

function retriveStreamToolsInfo(bossId, reset = true) {
  const streamtoolObj = store.getState()?.streamTools[bossId];

  const streamtoolSubscribed = streamtoolObj?.streamtoolSubscribed;
  const selectedDestinations = streamtoolObj?.selectedDestinations;
  const helperDestination = streamtoolObj?.helperDestination;
  const isStreamtoolsStreaming = streamtoolObj?.isStreamtoolsStreaming;

  const {
    unit: { unit },
  } = store.getState();
  return async (dispatch) => {
    try {
      const { getStreamToolsData } = liveStreamServices;
      dispatch(unitActions.getStreamToolDestination(bossId));
      let res = await getStreamToolsData(bossId);

      if (res) {
        const {
          token,
          live_managers,
          input_id,
          fallback_slate_url,
          fallback_slate_name,
          logo_url,
          x,
          y,
          toggle_state,
          fps,
          video_bitrate,
          audio_bitrate,
          selected_zone,
          profile,
          logo_zoom,
          logo_height,
          logo_width,
          slate_height,
          slate_width,
        } = res;

        if (reset) {
          resetAll();
        }
        dispatch({
          type: streamToolConstants.CHANGE_STREAM_TOOL_MODE,
          payload: { bossId, isActive: toggle_state },
        });

        dispatch({
          type: streamToolConstants.EL_STREAM_TOKEN,
          payload: { bossId, streamToken: token },
        });
        dispatch({
          type: streamToolConstants.EL_STREAM_LIVE_MANAGER_ID,
          payload: {
            bossId,
            streamLiveManageId: live_managers?.[selected_zone] ?? null,
          },
        });
        dispatch({
          type: streamToolConstants.EL_STREAM_INPUT_ID,
          payload: { bossId, streamInputId: input_id },
        });
        dispatch({
          type: streamToolConstants.EL_FALLBACK_SLATE_URL,
          payload: { bossId, fallBackSlateUrl: fallback_slate_url },
        });
        dispatch({
          type: streamToolConstants.EL_FALLBACK_SLATE_NAME,
          payload: { bossId, fallBackSlateName: fallback_slate_name },
        });
        dispatch({
          type: streamToolConstants.EL_WATERMARK_URL,
          payload: { bossId, watermarkUrl: logo_url },
        });
        dispatch({
          type: streamToolConstants.EL_WATERMARK_X,
          payload: { bossId, x: x },
        });
        dispatch({
          type: streamToolConstants.EL_WATERMARK_Y,
          payload: { bossId, y: y },
        });
        dispatch({
          type: streamToolConstants.EL_WATERMARK_ZOOM,
          payload: { bossId, logoZoom: logo_zoom },
        });
        dispatch({
          type: streamToolConstants.EL_STREAMTOOLS_SUBSCRIBED,
          payload: { bossId, streamtoolSubscribed: true },
        });
        dispatch({
          type: streamToolConstants.SET_FPS,
          payload: { bossId, fps },
        });
        dispatch({
          type: streamToolConstants.SET_VIDEO_BITRATE,
          payload: { bossId, videoBitrate: video_bitrate },
        });
        dispatch({
          type: streamToolConstants.SET_AUDIO_BITRATE,
          payload: { bossId, audioBitrate: audio_bitrate },
        });
        dispatch({
          type: streamToolConstants.LOGO_HEIGHT,
          payload: { bossId, logoHeight: logo_height ?? null },
        });
        dispatch({
          type: streamToolConstants.LOGO_WIDTH,
          payload: { bossId, logoWidth: logo_width ?? null },
        });
        dispatch({
          type: streamToolConstants.SLATE_HEIGHT,
          payload: { bossId, slateHeight: slate_height ?? null },
        });
        dispatch({
          type: streamToolConstants.SLATE_WIDTH,
          payload: { bossId, slateWidth: slate_width ?? null },
        });
        if (selected_zone) {
          dispatch({
            type: streamToolConstants.SET_STEAMTOOL_ZONE,
            payload: { bossId, selectedZone: selected_zone },
          });
        }
        if (profile) {
          dispatch({
            type: streamToolConstants.SET_PROFILE,
            payload: { bossId, profile },
          });
        }

        dispatch(setInitialDataLoadedFlag(bossId, true));
        dispatch(showStreamtoolsLoader(bossId, false));
        return res;
      } else if (streamtoolSubscribed) {
        /**
         * Clean up when stripe subscription expires
         */
        selectedDestinations &&
          selectedDestinations.length > 0 &&
          (await Promise.all(
            selectedDestinations.map(
              async (e) =>
                await dispatch(
                  destinationActions.removeStreamToolDestination(e?.id),
                ),
            ),
          ));

        helperDestination &&
          helperDestination?.id &&
          (await dispatch(
            destinationActions.deleteSingleDestination(
              helperDestination?.id,
              bossId,
            ),
          ));

        const v1 = await unitService.getChannelServicesAndDestination(bossId);
        if (v1) {
          v1?.selected_destination &&
            (await dispatch(
              destinationActions.deleteSingleDestination(
                v1?.selected_destination,
                bossId,
              ),
            ));
        }

        if (unit?.status !== 'offline' && isStreamtoolsStreaming) {
          await dispatch(stopStreamTools(bossId));
        }
        dispatch({ type: streamToolConstants.SET_INITIAL_SET, bossId });
        const { storeStreamToolsData } = liveStreamServices;
        await storeStreamToolsData({
          bossId,
          toggleState: false,
        });
      } else {
        dispatch({
          type: streamToolConstants.EL_STREAMTOOLS_SUBSCRIBED,
          payload: { bossId, streamtoolSubscribed: false },
        });
        dispatch(setInitialDataLoadedFlag(bossId, true));
      }

      dispatch(showStreamtoolsLoader(bossId, false));

      function resetAll() {
        dispatch(showStreamtoolsLoader(bossId, true));

        // dispatch({ type: streamToolConstants.EL_STREAMTOOLS_RESET, bossID });
        dispatch({
          type: streamToolConstants.CHANGE_STREAM_TOOL_MODE,
          payload: { bossId, isActive: false },
        });
      }
    } catch (e) {
      dispatch(showStreamtoolsLoader(bossId, false));
    }
  };
}

function updateAudio(bossId, volume) {
  return async (dispatch) => {
    const socket = await dispatch(getSocket(bossId));
    // let EXPONENT = Math.log(12290) / Math.log(6);
    function logBaseX(val, base) {
      return Math.log(val) / Math.log(base);
    }
    const percentToDecibel = (percent) => {
      return logBaseX((percent + 9) / 78, 1.029);
    };

    socket.send({
      data: {
        set_volume: round(percentToDecibel(volume) * 10),
        // Math.round(
        //   volume * 5.1 + Math.pow(Math.max(volume - 40, 0) / 10, EXPONENT),
        // ),
        goto_prod: ['volume'],
      },
    });
    // return new Promise(async (resolve) => {
    //   // const { streamLiveManageId: liveManagerId } =
    //   //   store.getState().easyLive
    //   //     .easyliveStreamInformationForWebsocketConnection;

    //   let EXPONENT = Math.log(12290) / Math.log(6);
    //   socket.init = async () => {
    //     socket.send({
    //       data: {
    //         set_volume: Math.round(
    //           volume * 5.1 + Math.pow(Math.max(volume - 40, 0) / 10, EXPONENT),
    //         ),
    //         goto_prod: ['volume'],
    //       },
    //     });
    //     resolve();
    //   };
    //   socket.init();
    // });
  };
}

function storeImageInS3(file, width, height, bossId) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const socket = await dispatch(getSocket(bossId));
        let bucket = 'image';
        let directory = 'display';
        if (
          file.type === 'image/gif' ||
          file.type === 'image/png' ||
          file.type === 'image/webp' ||
          file.type === 'image/avif'
        ) {
          bucket = 'static';
        }
        if (file.type === 'video/mp4') {
          bucket = 'animation';
          directory = 'v2';
        }
        socket.init = () => {
          socket.send(
            JSON.stringify({
              init: false,
            }),
          );
          socket.send(
            JSON.stringify({
              website: {
                scope: scope(),
                uploads3_get: {
                  directory,
                  bucket,
                  width,
                  height,
                  alpha: true,
                  mime_type: file.type,
                },
              },
            }),
          );
        };
        socket.onmessage = async (msg) => {
          try {
            const data = JSON.parse(msg.data);
            const headers = {
              'Content-Type': file.type,
              'x-amz-acl': 'public-read',
            };
            if (data?.customer?.uploads3_get) {
              const urlData = data?.customer?.uploads3_get;
              await fetch(urlData.signed_request, {
                method: 'PUT',
                headers: headers,
                body: file,
              }).catch(reject);
              if (
                file.type === 'image/gif' ||
                file.type === 'image/png' ||
                file.type === 'image/webp' ||
                file.type === 'image/avif' ||
                file.type === 'video/mp4'
              ) {
                resolve([urlData.url, width, height]);
                return;
              } else {
                resolve(await getResizedUrl(urlData));
              }
            }
          } catch (err) {
            reject();
          }
        };
        async function getResizedUrl(urlData) {
          return new Promise(async (resolve) => {
            let response = await fetch(`${urlData.url}.json`);
            while (response.status === 403) {
              // await new Promise((resolve) => {
              //   setTimeout(resolve, 1000);
              // });
              response = await fetch(`${urlData.url}.json`);
            }

            if (response.ok) {
              let image = await response.json();
              resolve([
                image.resized.url,
                image.resized.width,
                image.resized.height,
              ]);
            } else {
              reject(i18n.t('NOTABLETOUPLOAD'));
            }
          });
        }
        socket.init();
      } catch (err) {
        reject();
      }
    });
  };
}

function setWaterMark(url, width, height, X, Y, bossId, logo_zoom) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { streamLiveManageId: liveManagerId, streamInputId: inputId } =
          store.getState().streamTools?.[bossId];
        const LOGO_LINE_ID = 600000002;
        const zoom = (1980 * logo_zoom) / width;
        const X00 = X; //+ X * 0.1
        const Y00 = Y; //+ Y * 0.1
        let y = Math.ceil(1080 * (Y00 / 100));
        let x = Math.ceil(1920 * (X00 / 100));
        const inputLiveManager = inputId;
        const socket = await dispatch(getSocket(bossId));
        const StoreWaterMarkData = () => {
          dispatch({
            type: streamToolConstants.EL_WATERMARK_URL,
            payload: { bossId, watermarkUrl: url },
          });
          dispatch({
            type: streamToolConstants.EL_WATERMARK_X,
            payload: { bossId, x: X },
          });
          dispatch({
            type: streamToolConstants.EL_WATERMARK_Y,
            payload: { bossId, y: Y },
          });
          dispatch({
            type: streamToolConstants.EL_WATERMARK_ZOOM,
            payload: { bossId, logoZoom: logo_zoom },
          });
          dispatch({
            type: streamToolConstants.LOGO_HEIGHT,
            payload: { bossId, logoHeight: height },
          });
          dispatch({
            type: streamToolConstants.LOGO_WIDTH,
            payload: { bossId, logoWidth: width },
          });
        };
        let isUploadingWaterMark = true; //Required after removing logo, it gets readded by below onMessage

        socket.init = () => {
          socket.send(
            JSON.stringify({
              init: liveManagerId ?? false,
            }),
          );
        };
        socket.onmessage = async (msg) => {
          try {
            const data = JSON.parse(msg.data);
            if (
              (!!data?.init?.customer?.current_live ||
                data?.init?.customer?.current_live === false) &&
              isUploadingWaterMark
            ) {
              const draft_display_setting =
                data.init.customer.current_live.draft_display_settings ?? [];
              let check = false;
              draft_display_setting.forEach((display_settings) => {
                if (
                  display_settings.line_id === LOGO_LINE_ID &&
                  display_settings.typename === 'image'
                ) {
                  display_settings.url = url;
                  display_settings.img_zoom = zoom;
                  display_settings.y = y;
                  display_settings.x = x;
                  display_settings.natural_width = width;
                  display_settings.natural_height = height;
                  display_settings.fit = 'fill';
                  check = true;
                }
              });
              if (!check) {
                draft_display_setting.push(
                  ...[
                    {
                      y: y,
                      x: x,
                      natural_height: height,
                      natural_width: width,
                      img_zoom: zoom,
                      effect_end: 'fadeOut',
                      transparency: 100,
                      invert: 0,
                      effect_end_delay: 0,
                      name: 'image_1',
                      effect_end_duration: 0,
                      typename: 'image',
                      effect_begin_duration: 0,
                      saturate: 100,
                      effect_begin_delay: 0,
                      url: url,
                      single_loop_apng: false,
                      huer: 0,
                      line_id: LOGO_LINE_ID,
                      ct: 100,
                      anim_time: 0,
                      rotationY: 0,
                      sepia: 0,
                      effect_init: 'static',
                      effect_begin: 'fadeIn',
                      opacity: 100,
                      br: 100,
                      rotationX: 0,
                      rotationZ: 0,
                      anim_type: 'right_to_left',
                      gs: 0,
                      blur: 0,
                      nice_name: 'LOGO',
                      fit: 'fill',
                    },
                    {
                      line_id: 600000002,
                      name: 'placeholder_2',
                      typename: 'placeholder',
                    },
                  ],
                );
              }
              socket.send(
                JSON.stringify({
                  website: {
                    scope: scope(),
                    set_display_settings: draft_display_setting,
                    goto_prod: ['display'],
                  },
                }),
              );
              socket.send(
                JSON.stringify({
                  website: {
                    goto_prod: ['display'],
                    scope: scope(),
                    set_display_active: {
                      image_demo: false,
                      [inputLiveManager]: scope(),
                      image_1: scope(),
                    },
                  },
                }),
              );

              StoreWaterMarkData();
              await dispatch(
                updateStreamToolsData({
                  bossId,
                  logoUrl: url,
                  x: X,
                  y: Y,
                  logoZoom: logo_zoom,
                  logoHeight: height,
                  logoWidth: width,
                }),
              );
              isUploadingWaterMark = false;
              resolve();
            }
          } catch (err) {
            reject();
          }
        };
        socket.init();
      } catch (err) {
        reject();
      }
    });
  };
}

function setFallbackSlate(url, width, height, fileName, bossId) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { streamLiveManageId: liveManagerId } =
          store.getState().streamTools?.[bossId];
        const RTMP_LINE_ID = 600000003;
        const socket = await dispatch(getSocket(bossId));

        const StoreWaterMarkData = () => {
          dispatch({
            type: streamToolConstants.EL_FALLBACK_SLATE_URL,
            payload: { bossId, fallBackSlateUrl: url },
          });
          dispatch({
            type: streamToolConstants.EL_FALLBACK_SLATE_NAME,
            payload: { bossId, fallBackSlateName: fileName },
          });
          dispatch({
            type: streamToolConstants.SLATE_HEIGHT,
            payload: { bossId, slateHeight: height },
          });
          dispatch({
            type: streamToolConstants.SLATE_WIDTH,
            payload: { bossId, slateWidth: width },
          });
        };

        socket.init = () => {
          socket.send(
            JSON.stringify({
              init: liveManagerId ?? false,
            }),
          );
        };
        socket.onmessage = async (msg) => {
          try {
            const data = JSON.parse(msg.data);
            if (
              !!data?.init?.customer?.current_live ||
              data?.init?.customer?.current_live === false
            ) {
              const draft_display_setting =
                data.init.customer.current_live.draft_display_settings ?? [];
              let check = false;
              draft_display_setting.forEach((display_settings) => {
                if (display_settings.typename === 'input') {
                  display_settings.fit = 'fill';
                }
                if (
                  display_settings.line_id === RTMP_LINE_ID &&
                  display_settings.typename === 'image'
                ) {
                  display_settings.url = url;
                  display_settings.natural_width = width;
                  display_settings.natural_height = height;
                  display_settings.fit = 'fill';
                  check = true;
                }
                if (
                  display_settings.line_id === RTMP_LINE_ID &&
                  display_settings.typename === 'input' &&
                  display_settings.lcl !== 'image_2'
                ) {
                  display_settings.lcl = 'image_2';
                  display_settings.fit = 'fill';
                }
              });
              if (!check) {
                draft_display_setting.push(
                  ...[
                    {
                      y: 0,
                      x: 0,
                      natural_height: height,
                      natural_width: width,
                      img_zoom: 100,
                      effect_end: 'fadeOut',
                      transparency: 100,
                      invert: 0,
                      effect_end_delay: 0,
                      name: 'image_2',
                      effect_end_duration: 0,
                      typename: 'image',
                      effect_begin_duration: 0,
                      saturate: 100,
                      effect_begin_delay: 0,
                      url: url,
                      single_loop_apng: false,
                      huer: 0,
                      line_id: RTMP_LINE_ID,
                      ct: 100,
                      anim_time: 0,
                      rotationY: 0,
                      sepia: 0,
                      effect_init: 'static',
                      effect_begin: 'fadeIn',
                      opacity: 100,
                      br: 100,
                      rotationX: 0,
                      rotationZ: 0,
                      anim_type: 'right_to_left',
                      gs: 0,
                      blur: 0,
                      nice_name: 'fallback',
                      fit: 'fill',
                    },
                    {
                      line_id: RTMP_LINE_ID,
                      name: 'placeholder_3',
                      typename: 'placeholder',
                    },
                  ],
                );
              }
              socket.send(
                JSON.stringify({
                  website: {
                    scope: scope(),
                    set_display_settings: draft_display_setting,
                    goto_prod: ['display'],
                  },
                }),
              );

              StoreWaterMarkData();
              await dispatch(
                updateStreamToolsData({
                  bossId,
                  fallBackSlateUrl: url,
                  fallBackSlateName: fileName,
                  slateHeight: height,
                  slateWidth: width,
                }),
              );
              resolve();
            }
          } catch (err) {
            reject();
          }
        };

        socket.init();
      } catch (err) {
        reject();
      }
    });
  };
}

function removeWaterMark(bossId) {
  return async (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        const storeWaterMarkData = () => {
          dispatch({
            type: streamToolConstants.EL_WATERMARK_URL,
            payload: { bossId, watermarkUrl: '' },
          });
          dispatch({
            type: streamToolConstants.EL_WATERMARK_X,
            payload: { bossId, x: 0 },
          });
          dispatch({
            type: streamToolConstants.EL_WATERMARK_Y,
            payload: { bossId, y: 0 },
          });
        };

        const { streamLiveManageId: liveManagerId, streamInputId: inputId } =
          store.getState().streamTools?.[bossId];
        const inputLiveManager = `${inputId}`;
        const socket = await dispatch(getSocket(bossId));

        socket.init = async () => {
          try {
            socket.send(
              JSON.stringify({
                init: liveManagerId,
              }),
            );
            socket.send(
              JSON.stringify({
                website: {
                  goto_prod: ['display'],
                  scope: scope(),
                  set_display_active: {
                    image_demo: false,
                    [inputLiveManager]: scope(),
                    image_1: false,
                  },
                },
              }),
            );
            storeWaterMarkData();
            await dispatch(
              updateStreamToolsData({
                bossId,
                logoUrl: '',
                x: 0,
                y: 0,
              }),
            );
            resolve();
          } catch (err) {
            resolve();
          }
        };
        socket.init();
      } catch (err) {
        resolve();
      }
    });
  };
}

function urlBuilder(token, selectedZone) {
  if (selectedZone === 'ap-mum-1') {
    // For specific Mumbai zone, we have to pass ireland zone only in url
    selectedZone = getValueForEnvironment('eu-irl-1', 'eu-irl-4');
  }
  if (selectedZone === 'ap-syd-1') {
    // For specific Sydney zone, we have to pass Oreland zone only in url
    selectedZone = 'us-ore-4';
  }
  return `wss://ws-${selectedZone}.studio.liveu.tv/websocket/public/client/?access_token=${token}`;
}

function scope() {
  return Math.floor(Math.random() * 1000000000 + 100000000);
}

function getSocket(bossId) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      let newSocket;
      let {
        streamToken,
        socket: existingSocket,
        selectedZone,
      } = store.getState().streamTools?.[bossId];

      const getFreshSocket = async () => {
        if (!streamToken) {
          const { getStreamToolsData } = liveStreamServices;
          let res = await getStreamToolsData(bossId);
          if (res) {
            // let res1 = res.data?.data?.response?.data;
            streamToken = res.token;
            dispatch({
              type: streamToolConstants.EL_STREAM_TOKEN,
              payload: { bossId, streamToken },
            });
          }
          // else if (res.status === 401) {
          //   notify('error', i18n.t('SUBSCRIBESTREAMTOOL'));
          //   return null;
          // }
          else {
            notify('error', i18n.t('SOMETHINGGOESWRONG'));
            return null;
          }
        }
        const socket = new WebSocket(urlBuilder(streamToken, selectedZone));
        dispatch({
          type: streamToolConstants.EL_STREAM_SOCKET,
          payload: { bossId, socket },
        });
        return socket;
      };

      if (existingSocket?.url) {
        newSocket = existingSocket;
      } else {
        newSocket = await getFreshSocket();
        if (!newSocket) {
          reject('Socket init failed!!');
          return;
        }
      }
      if (newSocket.readyState === 1) {
        resolve(newSocket);
        return;
      } else if (newSocket.readyState === 2 || newSocket.readyState === 3) {
        newSocket = await getFreshSocket();
      }
      newSocket.onopen = () => {
        resolve(newSocket);
      };
    });
  };
}

function createLiveManager(unit) {
  const { getSocket } = liveStreamActions;

  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        let socket = await dispatch(getSocket(unit.BOSSID));
        let { selectedZone } = store.getState().streamTools?.[unit?.BOSSID];
        socket.init = () => {
          socket.send(
            JSON.stringify({
              init: false,
            }),
          );

          socket.send(
            JSON.stringify({
              website: {
                scope: scope(),
                infos_init: true,
              },
            }),
          );
        };

        socket.onmessage = async (msg) => {
          let data = JSON.parse(msg.data);
          if (!!data?.init?.customer?.id) {
            dispatch({
              type: streamToolConstants.STREAMTOOL_CLIENTID,
              payload: {
                bossId: unit.BOSSID,
                streamtoolClientId: data?.init?.customer?.id,
              },
            });
          }
          if (data?.customer?.infos_init) {
            let liveManagerPayload = {
              website: {
                scope: scope(),
                live_manager: {
                  action: 'create',
                  values: {
                    preview: data.customer.infos_init.preview,
                    title: data.customer.infos_init.title,
                    facebook_sponsor_id:
                      data.customer.infos_init.facebook_sponsor_id,
                    facebook_stream_type:
                      data.customer.infos_init.facebook_stream_type,
                    description: data.customer.infos_init.description,
                    visibility: data.customer.infos_init.visibility,
                  },
                },
              },
            };
            socket.send(JSON.stringify(liveManagerPayload));
          }
          if (data?.customer?.live_manager?.id) {
            dispatch({
              type: streamToolConstants.EL_STREAM_LIVE_MANAGER_ID,
              payload: {
                bossId: unit.BOSSID,
                streamLiveManageId: data.customer.live_manager?.id,
              },
            });
            await dispatch(
              updateStreamToolsData({
                bossId: unit.BOSSID,
                liveManagerId: data.customer.live_manager?.id,
                selectedZone,
              }),
            );
            resolve(true);
          }

          if (data?.customer?.error && data?.customer?.error !== 'success') {
            socket.onError(data?.customer?.error);
          }
        };
        socket.onError = async () => {
          reject(false);
        };
        socket.init();
      } catch (error) {
        reject(error);
      }
    });
  };
}

function getStreamName(unit) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { streamLiveManageId: liveManagerId } =
          store.getState().streamTools?.[unit.BOSSID];
        const { getSocket } = liveStreamActions;
        const socket = await dispatch(getSocket(unit.BOSSID));
        let lock = false;
        socket.init = () => {
          socket.send(
            JSON.stringify({
              init: liveManagerId,
            }),
          );
        };

        socket.onmessage = async (msg) => {
          let data = JSON.parse(msg.data);

          if (
            data?.init?.customer?.current_live?.draft_display_settings &&
            !lock
          ) {
            lock = true;
            const draftDisplaySettings =
              data?.init?.customer?.current_live?.draft_display_settings;
            let displaySetting = draftDisplaySettings.find(
              (displaySetting) =>
                displaySetting.line_id === RTMP_LINE_ID &&
                displaySetting.typename === 'input',
            );
            if (!!displaySetting) {
              dispatch({
                type: streamToolConstants.EL_STREAM_INPUT_ID,
                payload: {
                  bossId: unit.BOSSID,
                  streamInputId: displaySetting.name,
                },
              });
              await dispatch(
                registerStreamToolsDestinationWithLuc(
                  {
                    streamName: displaySetting.stream_name,
                    bossId: unit.BOSSID,
                  },
                  unit.BOSSID,
                ),
              );
              await dispatch(startUnitForStreamTool(unit.BOSSID));
              resolve(true);
            } else {
              let createNewStream = {
                website: {
                  scope: scope(),
                  objs: {
                    action: 'edit',
                    model: 'camera',
                    values: {
                      line_id: RTMP_LINE_ID,
                      input_type: 'liveu',
                    },
                  },
                },
              };
              socket.send(JSON.stringify(createNewStream));
              lock = false;
              socket.init();
            }
          }
        };

        socket.init();
      } catch (error) {
        reject(error);
      }
    });
  };
}

function receiveStreamFromLst(unit) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { streamLiveManageId: liveManagerId } =
          store.getState().streamTools?.[unit.BOSSID];
        const { getSocket } = liveStreamActions;
        const socket = await dispatch(getSocket(unit.BOSSID));
        let isStartMsgSent = false;
        let startConditionOne = false;
        let startConditionTwo = false;
        socket.init = () => {
          socket.send(
            JSON.stringify({
              init: liveManagerId,
            }),
          );
          socket.send(
            JSON.stringify({
              website: { scope: scope(), send_publish: {} },
            }),
          );
        };

        socket.onmessage = async (msg) => {
          let data = JSON.parse(msg.data);
          if (data?.customer?.home?.updated) {
            startConditionOne = true;
          }
          if (
            data?.[liveManagerId]?.connected &&
            data?.[liveManagerId]?.start === 1
          ) {
            startConditionTwo = true;
          }
          if (!isStartMsgSent && startConditionOne && startConditionTwo) {
            isStartMsgSent = true;

            socket.send(
              JSON.stringify({
                website: { scope: scope(), send_start: {} },
              }),
            );
          }

          if (data?.[liveManagerId]?.broadcaster_status?.state === 'started') {
            resolve(true);
          }

          if (
            data?.[liveManagerId]?.error &&
            data?.[liveManagerId]?.error !== 'success'
          ) {
            socket.onError(data?.[liveManagerId]?.error, data);
          }
        };

        socket.onError = async (e) => {
          if (e === 'no server available') {
            function progressMsg(seconds) {
              dispatch({
                type: streamToolConstants.SET_GOLIVE_INFO,
                payload: {
                  bossId: unit.BOSSID,
                  progressInfo: `${i18n.t('NOSERVERAVL')} ${seconds} ${i18n.t(
                    'SECONDS',
                  )}`,
                },
              });
              if (seconds > 0) {
                setTimeout(() => {
                  progressMsg(seconds - 1);
                }, 1000);
              } else {
                dispatch({
                  type: streamToolConstants.SET_GOLIVE_INFO,
                  payload: {
                    bossId: unit.BOSSID,
                    progressInfo: `${i18n.t('PROVISIONINGSERVER')}`,
                  },
                });
              }
            }
            setTimeout(() => {
              socket.send(
                JSON.stringify({
                  website: { scope: scope(), send_publish: {} },
                }),
              );
            }, 2000);
            progressMsg(2);
            return;
          }
          // if (e === "Publish is only available when there's no live stream") {
          //   return;
          // }
          if (e.live_max_number) {
            await killAllLiveManger(socket, liveManagerId);
            await dispatch(receiveStreamFromLst(unit));
            resolve();
            return;
          }

          dispatch(stopStream(unit));
          reject(e);
        };

        socket.init();
      } catch (error) {
        reject(error);
      }
    });
  };
}

function provideFeedToDestinations(unit) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          streamLiveManageId: liveManagerId,
          streamInputId,
          selectedDestinations: destinations,
        } = store.getState().streamTools?.[unit.BOSSID];

        const socket = await dispatch(getSocket(unit.BOSSID));
        let count = 0;

        socket.init = () => {
          socket.send(
            JSON.stringify({
              init: liveManagerId,
            }),
          );
          destinations.forEach((item) => {
            let pathUrl =
              item?.streaming_destination.streaming_ingest?.primary_url;

            let username =
              item?.streaming_destination?.streaming_ingest?.username;
            let password =
              item?.streaming_destination?.streaming_ingest?.password;
            if (username && password) {
              // passing path in rtmp://username:password@url/streamname for 'Generic with Auth','dacast','Level3 destination
              let primaryUrl =
                item?.streaming_destination?.streaming_ingest?.primary_url?.replace(
                  /^rtmp?:\/\//,
                  '',
                );
              pathUrl = `rtmp://${username}:${password}@${primaryUrl}`;
            }
            socket.send(
              JSON.stringify({
                website: {
                  scope: scope(),
                  objs: {
                    action: 'edit',
                    model: 'broadcast',
                    values: {
                      input_type: 'other',
                      nick_name:
                        item.streaming_destination
                          .streaming_destination_outputs[0].stream_name,
                      stream_name:
                        item.streaming_destination
                          .streaming_destination_outputs[0].stream_name,
                      path: pathUrl,
                      continuous_timecode: false,
                      metadata_creation_time: false,
                    },
                  },
                },
              }),
            );
          });
        };
        socket.onmessage = async (msg) => {
          let data = JSON.parse(msg.data);
          if (data?.customer?.objs?.model_id) {
            socket.send(
              JSON.stringify({
                website: {
                  scope: scope(),
                  broadcast_select: {
                    nb: 0,
                    identifier: data.customer.objs.model_id,
                    service: 'easylive',
                    source: 'main',
                    infos: {},
                  },
                },
              }),
            );
            count += 1;
            if (count === destinations.length) {
              storeStreamToolsInfo(unit.BOSSID);
              socket.send(
                JSON.stringify({
                  website: {
                    scope: scope(),
                    set_display_active: {
                      [streamInputId]: scope(),
                    },
                    goto_prod: ['display'],
                  },
                }),
              );
              try {
                const { ovpCredentials } = store.getState().authentication.user;

                const { isStreamToolActive, selectedDestinations } =
                  store.getState().streamTools?.[unit.BOSSID];
                if (isStreamToolActive) {
                  selectedDestinations.forEach((selectedDestination) => {
                    dispatch(
                      switchToDestination(
                        selectedDestination,
                        ovpCredentials,
                        unit,
                        isStreamToolActive,
                      ),
                    );
                  });
                }
              } catch (err) {}
              resolve(true);
            }
          }
        };
        socket.init();
      } catch (error) {
        reject(error);
      }
    });
  };
}

function registerStreamToolsDestinationWithLuc(values, bossId) {
  return async (dispatch) => {
    return new Promise(async (resolve) => {
      const ID = guid();
      const streamToolsData = store.getState()?.streamTools[bossId];
      const { profile, fps, videoBitrate, audioBitrate } = streamToolsData;

      const awsPayload = {
        destinationName: ID,
        selectedProfile: profile ?? '1920 x 1080 Widescreen (16:9) 60 fps',
        primary_url: 'rtmp://p.easy.live/live/',
        secondary_url: '',
        stream_name: values.streamName,
        username: '',
        password: '',
        id: ID,
        streaming_provider: 'Generic',
        overrideResolution: values.resolutionBox
          ? values.overrideResolution.value
          : '',
        overrideFramerate: fps ?? '',
        overrideBitrate: videoBitrate ?? '',
        audio_bitrate_override: audioBitrate ?? '',
      };
      await dispatch(
        commonAction.createAwsDestination(awsPayload, values.bossId),
      );
      resolve();
    });
  };
}

function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
}

function setBitrate(unit) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          streamLiveManageId: liveManagerId,
          fps,
          audioBitrate,
          videoBitrate,
          profile,
        } = store.getState().streamTools?.[unit.BOSSID];
        const { getSocket } = liveStreamActions;
        const socket = await dispatch(getSocket(unit.BOSSID));

        socket.init = () => {
          socket.send(
            JSON.stringify({
              init: liveManagerId,
            }),
          );
        };

        socket.onmessage = (msg) => {
          const data = JSON.parse(msg.data);
          if (data?.init?.customer?.current_live) {
            let raw_to_encoded =
              data.init.customer?.current_live?.raw_to_encoded[0];
            let raw_to_raw = data.init.customer?.current_live?.raw_to_raw[0];

            if (!!videoBitrate) {
              raw_to_encoded['params']['video']['bitrate'] =
                parseInt(videoBitrate);
            } else {
              raw_to_encoded['params']['video']['bitrate'] = 4608;
            }

            if (!!fps) {
              if (fps === '12.5' || fps === '12.48' || fps === '14.98') {
                raw_to_raw['fps'] = 15000;
              } else if (fps === '24.97') {
                raw_to_raw['fps'] = 25000;
              } else {
                raw_to_raw['fps'] = parseFloat(fps).toFixed(2) * 1000;
              }
            } else {
              raw_to_raw['fps'] = 30000;
            }

            if (!!audioBitrate) {
              raw_to_encoded['params']['audio']['bitrate'] =
                parseFloat(audioBitrate).toFixed(2) / 1000;
            } else {
              raw_to_encoded['params']['audio']['bitrate'] = 128;
            }

            if (profile) {
              const regexp = /(\d+)\s*x\s*(\d+)/;
              const arr = profile.match(regexp);
              const width = parseInt(arr[1]);
              const height = parseInt(arr[2]);

              switch (profile) {
                case '854 x 480 Widescreen (16:9)':
                  raw_to_raw['width'] = 800;
                  raw_to_raw['height'] = 450;
                  break;
                case '640 x 480 Standard (4:3)':
                case '320 x 240 Standard (4:3)':
                  raw_to_raw['width'] = 640;
                  raw_to_raw['height'] = 360;
                  break;
                default:
                  raw_to_raw['width'] = width;
                  raw_to_raw['height'] = height;
              }
            } else {
              raw_to_raw['width'] = 1920;
              raw_to_raw['height'] = 1080;
            }

            socket.send(
              JSON.stringify({
                website: {
                  scope: scope(),
                  set_raw_to_encoded: [raw_to_encoded],
                },
              }),
            );

            socket.send(
              JSON.stringify({
                website: {
                  scope: scope(),
                  set_raw_to_raw: [raw_to_raw],
                },
              }),
            );
            resolve();
          }
        };

        socket.init();
      } catch (error) {
        reject(error);
      }
    });
  };
}

function goLive(unit, handleSRTCondition = null) {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      let p = 1;
      try {
        setprogressbarValue(p++);
        const data = handleSRTCondition && (await handleSRTCondition());
        if (data) {
          return;
        }
        if (!checkDestinationStatus(unit)) {
          notify('error', i18n.t('RELOGIN'));
          resolve();
          return;
        }
        setDnd(true);
        dispatch(startProgressBar(unit));
        setprogressbarValue(p++);
        await dispatch(createLiveManager(unit));
        setprogressbarValue(p++);
        await dispatch(killAllExistingBroadcaster(unit.BOSSID));
        setprogressbarValue(p++);
        await dispatch(getStreamName(unit));
        setprogressbarValue(p++);
        await dispatch(setBitrate(unit));
        setprogressbarValue(p++);
        await dispatch(receiveStreamFromLst(unit));
        setprogressbarValue(p++);
        await dispatch(provideFeedToDestinations(unit));
        setprogressbarValue(p);
        setDnd(false);
        dispatch(clearProgressBarInterval(unit));

        const helperDestination =
          store.getState().streamTools?.[unit?.BOSSID]?.helperDestination;
        await dispatch(
          isStreamtoolsStreaming(unit.BOSSID, {
            error: async () => {
              await dispatch(
                destinationActions.deleteSingleDestination(
                  helperDestination?.id,
                  unit.BOSSID,
                ),
              );
              await dispatch(
                destinationActions.getDestinationDetails(
                  null,
                  unit?.BOSSID,
                  true,
                ),
              );
            },
            success: async () => {
              const {
                socket,
                watermarkUrl,
                logoHeight,
                logoWidth,
                logoZoom,
                x,
                y,
                fallBackSlateUrl,
                fallBackSlateName,
                slateHeight,
                slateWidth,
              } = store.getState().streamTools?.[unit?.BOSSID];
              const volume = 69;
              // const EXPONENT = Math.log(12290) / Math.log(6);

              function logBaseX(val, base) {
                return Math.log(val) / Math.log(base);
              }
              const percentToDecibel = (percent) => {
                return logBaseX((percent + 9) / 78, 1.029);
              };

              //set watermark if exist
              if (!!watermarkUrl && !!logoWidth && !!logoHeight) {
                await dispatch(
                  setWaterMark(
                    watermarkUrl,
                    logoWidth,
                    logoHeight,
                    x,
                    y,
                    unit?.BOSSID,
                    logoZoom,
                  ),
                );
              }
              // set watermark if exist
              if (fallBackSlateUrl && !!logoWidth && !!logoHeight) {
                await dispatch(
                  setFallbackSlate(
                    fallBackSlateUrl,
                    slateWidth,
                    slateHeight,
                    fallBackSlateName,
                    unit?.BOSSID,
                  ),
                );
              } else {
                await dispatch(
                  setFallbackSlate(
                    fallBackSlateUrl,
                    slateWidth,
                    slateHeight,
                    fallBackSlateName,
                    unit?.BOSSID,
                  ),
                );
              }
              //set volume to 69% as a default value
              socket.send(
                JSON.stringify({
                  data: {
                    set_volume: round(percentToDecibel(volume) * 10),
                    goto_prod: ['volume'],
                  },
                }),
              );
            },
          }),
        );
        setprogressbarValue(0);
      } catch (err) {
        setprogressbarValue(-1);
        setDnd(false);
        notify('error', i18n.t('SOMETHINGGOESWRONG'));
        resolve();
      } finally {
        setprogressbarValue(0);
        setDnd(false);
        dispatch(clearProgressBarInterval(unit));
        resolve();
      }
      function setprogressbarValue(progress) {
        dispatch({
          type: streamToolConstants.SET_GOLIVE_STAGE,
          payload: { bossId: unit.BOSSID, progress },
        });
      }
      function setDnd(dnd) {
        dispatch({
          type: streamToolConstants.SET_DND,
          payload: { bossId: unit.BOSSID, dnd },
        });
      }
    });
  };
}

function getProfileValue(streamingProvider) {
  let streamingProfile;

  let { providers } = store.getState().destination;

  providers.forEach((provider) => {
    if (provider?.name?.toLowerCase() === streamingProvider?.toLowerCase()) {
      provider.streaming_profiles.some((profile) => {
        if (profile.name.includes('1080')) {
          if (streamingProvider === 'SRT-OUT-Caller-Solo') {
            if (profile.name.includes('265')) {
              return false;
            }
            streamingProfile = profile.name;
            return true;
          }

          streamingProfile = profile.name;
          return true;
        }
        return false;
      });
      if (!streamingProfile) {
        streamingProfile =
          provider.streaming_profiles[provider.streaming_profiles.length - 1]
            ?.name;
      }
    }
  });

  return {
    label: streamingProfile,
    name: streamingProfile,
    value: streamingProfile,
  };
}

/**
 * not use
 * @function checkDestinationStatus
 * @returns
 */
function checkDestinationStatus(unit) {
  const {
    streamTools: {
      [unit.BOSSID]: { selectedDestinations: destinations },
    },
    authentication: {
      user: { ovpCredentials },
    },
  } = store.getState();
  let flag = true;
  destinations.forEach((destination) => {
    switch (
      destination.streaming_destination.streaming_provider.toLowerCase()
    ) {
      case 'wowza':
        if (!ovpCredentials) {
          flag = false;
          return;
        } else if (!JSON.parse(ovpCredentials)[unit.BOSSID + '_wowza']) {
          flag = false;
          return;
        }
        break;
      case 'facebook':
        if (!getLocalStorageItem('FB_access_token')) {
          notify('error', i18n.t('FBSESSIONEXPIRED'));
          flag = false;
        }
        break;
      case 'youtube':
        if (!cookie.get('YT_access_token')) {
          notify('error', i18n.t('YTSESSIONEXPIRED'));
          flag = false;
        }
        break;
      case 'linkedin':
        if (!cookie.get('LI_access_token')) {
          notify('error', i18n.t('LINKEDINSESSIONEXPIRED'));
          flag = false;
        }
        break;
      case 'vimeo':
        if (!cookie.get('vimeo_access_token')) {
          notify('error', i18n.t('VIMEOSESSIONEXPIRED'));
          flag = false;
        }
        break;
      default:
        break;
    }
  });
  return flag;
}

function killAllLiveManger(socket, liveManagerId) {
  return new Promise((resolve) => {
    try {
      socket.init = () => {
        socket.send(
          JSON.stringify({
            init: false,
          }),
        );
      };
      socket.onmessage = (msg) => {
        const data = JSON.parse(msg.data);
        if (data?.init) {
          socket.send(
            JSON.stringify({
              website: {
                scope: scope(),
                home: {
                  action: 'scheduled_ended',
                  values: {
                    today: true,
                    name: 'scheduled',
                    page: 0,
                  },
                },
              },
            }),
          );
        }
        if (data?.customer?.home?.response?.boxs?.length > 0) {
          const boxes = data?.customer?.home?.response?.boxs;
          boxes.forEach((box) => {
            if (box.id === liveManagerId) {
              return;
            }
            socket.send(
              JSON.stringify({
                init: box.id,
              }),
            );

            socket.send(
              JSON.stringify({
                website: {
                  scope: scope(),
                  send_stop: {},
                },
              }),
            );

            socket.send(
              JSON.stringify({
                website: {
                  scope: scope(),
                  home: {
                    action: 'scheduled_ended_delete',
                    values: {
                      id: box.id,
                    },
                  },
                },
              }),
            );
          });
          resolve();
        }
      };
      socket.init();
    } catch (error) {
      resolve();
    }
  });
}

function setLiveStreamProgressState(unit, progress) {
  return (dispatch) => {
    dispatch({
      type: streamToolConstants.SET_GOLIVE_STAGE,
      payload: { bossId: unit.BOSSID, progress },
    });
  };
}

function checkDestinationsHealthToGoLive(match) {
  //change final result to accomodate mmore then one object for same provider
  return async (dispatch) => {
    const {
      streamTools: {
        [match.params.bossId]: {
          selectedDestinations,
          isStreamToolActive,
          isUpdatingZone,
        },
      },
      unit: { unit },
      authentication: {
        user: { ovpCredentials },
      },
    } = store.getState();

    if (
      isStreamToolActive &&
      (selectedDestinations?.length === 0 ||
        unit.status === 'offline' ||
        isUpdatingZone)
    ) {
      return true;
    }
    let finalResult = [];
    function setFinalResult(result) {
      finalResult.push(result);
    }

    await Promise.all(
      selectedDestinations.map(async (selectedDes) => {
        if (selectedDes.streaming_destination?.streaming_provider) {
          const provider =
            selectedDes.streaming_destination?.streaming_provider;
          try {
            switch (provider.toLowerCase()) {
              case 'facebook':
                if (!!getLocalStorageItem('FB_access_token')) {
                  const data = await dispatch(
                    fbGetLiveVideoStatus(
                      selectedDes.streaming_destination.external_id,
                      match.params.bossId,
                      selectedDes.fb_page_id,
                    ),
                  );
                  data.status.toLowerCase() === 'unpublished' ||
                  data.status.toLowerCase() === 'scheduled_unpublished'
                    ? setFinalResult({
                        postState: 'preview',
                        postDescription: data.description,
                      })
                    : setFinalResult({
                        postId: data.video.id,
                        postState: data.status,
                        postDescription: data.description,
                      });
                } else {
                  setFinalResult({
                    postState: 'loggedout',
                    postId: '',
                    postDescription: '',
                  });
                }

                break;
              case 'linkedin':
                if (!cookie.get('LI_access_token')) {
                  setFinalResult({
                    postState: 'Not Started',
                  });
                  const l1 = await dispatch(
                    LIPostStatus(
                      selectedDes.streaming_destination.external_id,
                      match,
                    ),
                  );

                  if (l1.status.toLowerCase() === 'incomplete') {
                    setFinalResult({
                      postState: 'deleted',
                    });
                  }
                } else {
                  setFinalResult({
                    postState: 'loggedout',
                    postId: '',
                    postDescription: '',
                  });
                }

                break;
              case 'wowza':
                let wowzaToken =
                  JSON.parse(ovpCredentials)?.[`${match.params.bossId}_wowza`];
                if (!!wowzaToken) {
                  const v1 = await wowzaPostStatus(wowzaToken);
                  let d = new Date();
                  d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
                  cookie.set('wowza_long_token', wowzaToken, {
                    path: '/',
                    expires: d,
                    secure: true,
                  });
                  setFinalResult({
                    postState: v1.data.state,
                  });
                }
                break;
              case 'youtube':
                if (!!cookie.get('YT_access_token')) {
                  const ytdata = await dispatch(
                    getYTEventDetails(
                      selectedDes.streaming_destination.external_id,
                      match,
                    ),
                  );
                  (ytdata.status.lifeCycleStatus.toLowerCase() === 'testing' ||
                    ytdata.status.lifeCycleStatus.toLowerCase() === 'ready') &&
                  !ytdata.snippet.isDefaultBroadcast
                    ? setFinalResult({
                        postState: 'preview',
                        postDescription: ytdata.snippet.description,
                      })
                    : setFinalResult({
                        postState: ytdata.status.lifeCycleStatus,
                        postDescription: ytdata.snippet.description,
                      });
                } else {
                  setFinalResult({
                    postState: 'loggedout',
                    postId: '',
                    postDescription: '',
                  });
                }
                break;
              case 'periscope and twitter':
                const { id } = store.getState().periscope.ptbroadcast;
                if (!id || !!!cookie.get('periscope_access_token')) {
                  setFinalResult({
                    postState: 'loggedout',
                    postId: '',
                    postDescription: '',
                  });
                }
                break;
              default:
            }
          } catch (e) {
            if (provider.toLowerCase() === 'facebook') {
              if (e.status === 'DELETED') {
                setFinalResult({
                  postState: e.status,
                  postId: '',
                  postDescription: '',
                });
              }
            }
          }
        }
      }),
    );

    for (let item of finalResult) {
      if (
        [
          'vod',
          'deleted',
          'completed',
          'loggedout',
          'complete',
          'incomplete',
          'live_stopped',
          'processing',
        ].includes(item.postState.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  };
}

function isStreamtoolsStreaming(bossId, cb = null) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        let isLiveStreaming = false;
        let isCallbackFnExecuted = false;
        const {
          dnd,
          streamLiveManageId: liveManagerId,
          isStreamToolActive,
        } = store.getState()?.streamTools?.[bossId];
        // const { unit } = store.getState()?.unit;
        if (dnd) {
          resolve();
          return;
        }
        const socket = await dispatch(getSocket(bossId));
        if (!liveManagerId || !socket) {
          dispatch({
            type: streamToolConstants.EL_IS_STREAMTOOLS_STREAMING,
            payload: { bossId, isStreamtoolsStreaming: false },
          });
          resolve();
          return;
        }
        socket.init = () => {
          socket.send(
            JSON.stringify({
              init: liveManagerId,
            }),
          );
        };
        socket.onmessage = async (msg) => {
          try {
            let data = JSON.parse(msg.data);
            if (data?.init?.customer?.current_live?.start === 2) {
              isLiveStreaming = true;
              //if streamtools is streaming fetch broadcaster attached with it
              socket.send(
                JSON.stringify({
                  website: {
                    scope: scope(),
                    external_services: {
                      list: true,
                    },
                  },
                }),
              );
            }
            if (isLiveStreaming) {
              //if streamtools is streaming check if broadcaster are attached with livemanager

              if (
                data?.[liveManagerId]?.external_services?.services?.easylive
                  ?.broadcasters
              ) {
                const BROADCASTER =
                  data?.[liveManagerId]?.external_services?.services?.easylive
                    ?.broadcasters;
                const ACTUALBROADCASTER = BROADCASTER.filter(
                  (item) => !isNaN(item.identifier),
                );
                if (ACTUALBROADCASTER.length > 0) {
                  dispatch({
                    type: streamToolConstants.EL_IS_STREAMTOOLS_STREAMING,
                    payload: { bossId, isStreamtoolsStreaming: true },
                  });

                  if (cb && cb?.success && !isCallbackFnExecuted) {
                    isCallbackFnExecuted = true;
                    cb?.success();
                  }
                  resolve();
                } else {
                  // notify('error', i18n.t('PRVSGOLIVECANCEL'));
                  await executeCloseDownForStreamTools();
                  resolve();
                }
              }
            } else if (
              data?.init?.customer?.current_live?.start === 0 ||
              data?.init?.customer?.current_live?.start === 1
            ) {
              await executeCloseDownForStreamTools();
              resolve();
            }

            async function executeCloseDownForStreamTools() {
              dispatch({
                type: streamToolConstants.EL_IS_STREAMTOOLS_STREAMING,
                payload: { bossId, isStreamtoolsStreaming: false },
              });

              if (cb && cb?.error && !isCallbackFnExecuted) {
                isCallbackFnExecuted = true;
                cb?.error();
              }

              if (
                // unit?.status === 'streaming' &&
                isStreamToolActive
              ) {
                await unitService.stopUnit(bossId);
              }
            }
          } catch (err) {
            reject(err);
          }
        };
        socket.init();
      } catch (error) {
        reject(error);
      }
    });
  };
}

function killAllExistingBroadcaster(bossId) {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        const { dnd, streamLiveManageId: liveManagerId } =
          store.getState()?.streamTools?.[bossId];
        if (dnd) {
          // resolve();
          // return;
        }

        const socket = await dispatch(getSocket(bossId));
        if (!liveManagerId || !socket) {
          dispatch({
            type: streamToolConstants.EL_IS_STREAMTOOLS_STREAMING,
            payload: { bossId, isStreamtoolsStreaming: false },
          });
          resolve();
          return;
        }
        socket.init = () => {
          socket.send(
            JSON.stringify({
              init: liveManagerId,
            }),
          );
          socket.send(
            JSON.stringify({
              website: {
                scope: scope(),
                external_services: {
                  list: true,
                },
              },
            }),
          );
        };
        socket.onmessage = async (msg) => {
          let data = JSON.parse(msg.data);

          if (
            data?.[liveManagerId]?.external_services?.services?.easylive
              ?.broadcasters?.length > 0
          ) {
            let brodcaters =
              data?.[liveManagerId]?.external_services?.services?.easylive
                ?.broadcasters;
            brodcaters.forEach((broadcast) => {
              if (broadcast.identifier) {
                socket.send(
                  JSON.stringify({
                    website: {
                      scope: scope(),
                      objs: {
                        action: 'delete',
                        model: 'broadcast',
                        object_id: broadcast.identifier,
                      },
                    },
                  }),
                );
              }
            });
            resolve();
          } else if (
            data?.[liveManagerId]?.external_services?.services?.easylive
              ?.broadcasters?.length === 0
          ) {
            resolve();
          }
        };
        socket.init();
      } catch (error) {
        resolve(error);
      }
    });
  };
}

function stopStreamTools(bossId) {
  return async (dispatch) => {
    return new Promise(async (resolve) => {
      try {
        // await dispatch(killAllExistingBroadcaster());
        const { streamLiveManageId: liveManagerId } =
          store.getState().streamTools?.[bossId];

        // const { getSocket } = liveStreamActions;
        const socket = await dispatch(getSocket(bossId));

        socket.init = () => {
          socket.send(
            JSON.stringify({
              init: liveManagerId,
            }),
          );
        };
        socket.onmessage = async (msg) => {
          try {
            let data = JSON.parse(msg.data);
            if (!!data?.init?.customer?.id) {
              dispatch({
                type: streamToolConstants.STREAMTOOL_CLIENTID,
                payload: { bossId, streamtoolClientId: null },
              });
            }
            if (data?.init) {
              socket.send(
                JSON.stringify({
                  website: {
                    scope: guid(),
                    send_stop: {},
                  },
                }),
              );
              socket.send(
                JSON.stringify({
                  website: {
                    scope: guid(),
                    home: {
                      action: 'scheduled_ended_delete',
                      values: {
                        id: liveManagerId,
                      },
                    },
                  },
                }),
              );
              // await dispatch(liveStreamActions.isStreamtoolsStreaming(bossId));
              dispatch({
                type: streamToolConstants.EL_IS_STREAMTOOLS_STREAMING,
                payload: { bossId, isStreamtoolsStreaming: false },
              });
              dispatch(unitActions.getVideoDetails(bossId, 'video'));
              // dispatch(unitActions.getUnitsInfo());
              resolve();
            }
          } catch (err) {
            resolve();
          }
        };
        socket.init();
        // await unitService.stopUnit(bossId);
      } catch (err) {
        await unitService.stopUnit(bossId);
        resolve();
      }
    });
  };
}

function setStreamToolsZone(bossId, value) {
  const { getSocket } = liveStreamActions;
  // const { selectedZone:prevSelectedZone } = store.getState().streamTools?.[bossId];

  return async (dispatch) => {
    const existingSocket = await dispatch(getSocket(bossId));
    dispatch({
      type: streamToolConstants.SET_STREAMTOOL_ZONE_LOADER,
      payload: { bossId, value: true }, //start the loader
    });
    dispatch({
      type: streamToolConstants.SET_STEAMTOOL_ZONE,
      payload: { bossId, selectedZone: value },
    });
    await dispatch(
      updateStreamToolsData({
        bossId,
        selectedZone: value,
      }),
    ); // update selectedZone in db
    const res = await dispatch(retriveStreamToolsInfo(bossId, false)); // get/fetch latest data (specifically token);
    if (res?.isBadRegion) {
      notify('error', i18n.t('ZONE_NOT_SUPPORTED'));
    }

    if (res?.token && !res?.isBadRegion) {
      await existingSocket.close(); // close existing socket
      dispatch({
        type: streamToolConstants.EL_STREAM_SOCKET,
        payload: { bossId, socket: null },
      });
      await dispatch(getSocket(bossId)); // will create fresh socket
      dispatch({
        type: streamToolConstants.SET_STREAMTOOL_ZONE_LOADER,
        payload: { bossId, value: false }, //stop the loader
      });
    }
    dispatch({
      type: streamToolConstants.SET_STREAMTOOL_ZONE_LOADER,
      payload: { bossId, value: false }, //stop the loader
    });
  };
}

function refreshStreamToolsDestination() {
  return async (dispatch) => {
    await dispatch(unitActions.getStreamToolDestination());
    const { streamTools, unit } = store.getState();
    const { BOSSID: bossId } = unit.unit;
    const { selectedDestinations } = streamTools?.[bossId];
    if (selectedDestinations?.length === 0) {
      dispatch({
        type: streamToolConstants.SET_FPS,
        payload: { bossId, fps: null },
      });
      dispatch({
        type: streamToolConstants.SET_VIDEO_BITRATE,
        payload: { bossId, videoBitrate: null },
      });
      dispatch({
        type: streamToolConstants.SET_AUDIO_BITRATE,
        payload: { bossId, audioBitrate: null },
      });
      dispatch(
        updateStreamToolsData({
          bossId,
          fps: null,
          video_bitrate: null,
          audio_bitrate: null,
        }),
      );
    }
  };
}

function updateStreamToolsData(payload) {
  return async () => {
    const { storeStreamToolsData } = liveStreamServices;
    await storeStreamToolsData(payload);
  };
}

function startProgressBar(unit) {
  return (dispatch) => {
    let sec = 0;
    const { BOSSID: bossId } = unit;
    const logInterval = setInterval(() => {
      dispatch({
        type: streamToolConstants.SET_LOG_PROGRESS,
        payload: {
          bossId,
          logProgress: calculateLogProgressBar(sec++),
        },
      });
    }, 1000);
    dispatch({
      type: streamToolConstants.SET_LOG_INTERVAL,
      payload: {
        bossId,
        logInterval,
      },
    });
    function calculateLogProgressBar(x) {
      const base = 100;
      let percents = base - base / (Math.log((x * x) / 512.0 + 1) + 1);
      return parseInt(percents);
    }
  };
}

function clearProgressBarInterval(unit) {
  return (dispatch) => {
    try {
      const { BOSSID: bossId } = unit;
      const { logInterval } = store.getState().streamTools?.[unit.BOSSID];
      clearInterval(logInterval);
      dispatch({
        type: streamToolConstants.SET_LOG_PROGRESS,
        payload: {
          bossId,
          logProgress: 0,
        },
      });
    } catch {}
  };
}

function setStreamToolsProfile(profile, bossId) {
  return (dispatch) => {
    dispatch({
      type: streamToolConstants.SET_PROFILE,
      payload: { bossId, profile },
    });
  };
}

function setStreamToolsDestinations(BOSSID, dest) {
  return (dispatch) => {
    dispatch({
      type: streamToolConstants.GET_DSESTINATIONS_SUCCESS,
      payload: { bossId: BOSSID, dest },
    });
  };
}

function setStreamToolsAdvanceProfile(data, bossId) {
  return async (dispatch) => {
    const { fps, video_bitrate, audio_bitrate } = data;

    fps &&
      dispatch({
        type: streamToolConstants.SET_FPS,
        payload: { bossId, fps },
      });
    video_bitrate &&
      dispatch({
        type: streamToolConstants.SET_VIDEO_BITRATE,
        payload: { bossId, videoBitrate: video_bitrate },
      });
    audio_bitrate &&
      dispatch({
        type: streamToolConstants.SET_AUDIO_BITRATE,
        payload: { bossId, audioBitrate: audio_bitrate },
      });

    data.bossId = bossId;
    await dispatch(updateStreamToolsData(data));
  };
}
